import React from "react";
import Header from "../../../../components/header/Header";
import Home from "../../../../components/category/Home";
import Footer from "../../../../components/footer/Footer";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import gif from "../images/tt.gif";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let metaTitle = "";
  let metaDescription = "";

  if (data?.cms?.category.name == "Lifestyle") {
    metaTitle = "Check out top lifestyle brand reviews at Ofmum";
    metaDescription =
      "At Ofmum, we bring to our readers expert reviews, comparisons, and listicles for top lifestyle brands. Follow the link to read out blogs of your favorite genre.";
  } else if (data?.cms?.category.name == "Relationships") {
    metaTitle = "Solve the enigma of the relationship with Ofmum’s content";
    metaDescription =
      "At Ofmum, we bring to our readers expert reviews, comparisons, and listicles for top lifestyle brands. Follow the link to read out blogs of your favorite genre.";
  } else if (data?.cms?.category.name == "Womanhood") {
    metaTitle = "Ofmum brings you the most searched articles on womanhood";
    metaDescription =
      "Ofmum always tops the list of most searched topics for articles based on womanhood. Grown-ups it's time to get enlightened with the smartest recommendations.";
  } else {
    metaTitle = "Trustworthy Lifestyle and Health-related Information | Ofmum";
    metaDescription =
      "Ofmum is a trustworthy online platform to check out experts' views on different brands and hot topics including lifestyle, health, relationship, and more. Visit us.";
  }

  return (
    <div className="layout">
      <div className="banner-container">
        <Link to="https://www.a88wptk.com/4RQSJ/2Z9D1KZ/" target="_blank">
          <img src={gif} alt={"banner"} />
        </Link>
      </div>
      <Header
        category={data?.cms?.categories}
        domain={data?.cms?.domain}
        allPosts={allPosts}
        engine={options}
        pages={data?.cms?.pages}
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href={`https://${data?.cms?.domain?.domain_url}/${data?.cms?.category.name}/`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta name="webgains-site-verification" content="9za3nmsz" />

        <meta
          property="og:url"
          content={`https://${data?.cms?.domain?.domain_url}/${data?.cms?.category.name}/`}
        />
        <meta property="og:site_name" content={data?.cms?.domain?.name} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />
      </Helmet>

      <Home data={data} />
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@ofmum.com"} />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query category($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "8") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      category: CMS_Category(id: $slug) {
        name
      }
      categories: CMS_CategoryByDomain(domain_id: "8") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "8", category_id: $slug) {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
